import { enableAllPlugins } from "immer";
enableAllPlugins();
import { produce } from "immer";
import {
  createStatus,
  createRequestStatus,
  createSuccessStatus,
  createFailureStatus,
  // resetStatus,
  ActionStatus,
} from "../utils/reducerUtils";

interface MusicState {
  roomId: string;
  roomType: "host" | "guest";
}
// 글로벌 데이터의 상태 기본값. 데이터를 갖고 오기 전
export const initialState: MusicState = {
  roomId: "",
  roomType: "guest",
};

// 액션들의 집합.
export const actions = {
  SET_ROOM_DATA: "SET_ROOM_DATA",
};

// 액션 크리에이터. dispatch 내부에서 사용.
export const saveRoomIdAction = (roomId: string, roomType: "host" | "guest") => ({
  type: actions.SET_ROOM_DATA,
  roomId,
  roomType,
});

// 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.SET_ROOM_DATA:
        draft.roomId = action.roomId;
        draft.roomType = action.roomType;
        break;
      default:
        break;
    }
  });

export default reducer;
